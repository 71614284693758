import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SidebarSchedule() {
  const [sidebarVisible, setSidebarVisible] = useState(window.innerWidth > 768);
  const [showAbsolute, setShowAbsolute] = useState(true);
  
  const navigate = useNavigate();

  const menuItems = [
    { title: "Dashboard", icon: "bi bi-speedometer" },
    { title: "Users", icon: "bi bi-people-fill" },
    { title: "Cloud services", icon: "bi-bi-cloud" },
    { title: "Usage data", icon: "bi bi-user" },
    { title: "Server list", icon: "bi bi-server" },
  ];

  const handleResize = () => {
    setSidebarVisible(window.innerWidth > 768);
  };

  async function handleLogout() {
    try {
      
    } catch (error) {
     
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (sidebarVisible) {
      const timeoutId = setTimeout(() => {
        setShowAbsolute(false);
      }, 200);

      return () => clearTimeout(timeoutId);
    } else {
      setShowAbsolute(true);
    }
  }, [sidebarVisible]);

  const handleNavigate = (rute) => {
    navigate(`/${rute}`);
  };

  return (
    <>
      <div
        className={`fixed left-0 top-0 w-64 transition-all duration-300 transform bg-base-content-darker ${
          sidebarVisible ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <button
          className="absolute top-4 left-4 m-4 text-xl text-white focus:outline-none focus:ring-2 focus:ring-white"
          onClick={() => setSidebarVisible(!sidebarVisible)}
        >
          <i className="bi bi-list"></i>
        </button>
        <div className="text-white text-2xl font-bold py-4 px-6">Los Monachos</div>
        <hr className="border-b border-white opacity-25 my-4" />
        <ul className="space-y-4 px-4">
          {menuItems.map((item) => (
            <li
              key={item.title}
              className={`flex items-center justify-between py-2 px-4 rounded-lg hover:bg-base-content transition-colors duration-300`}
            >
              <a
                href="#"
                className="text-white"
                onClick={() => handleNavigate(item.title.toLowerCase())}
              >
                <i className={item.icon} />
                <span className="ml-2">{item.title}</span>
              </a>
            </li>
          ))}
        </ul>
        <div className="flex items-center justify-center px-6 py-4 bg-base-content">
          <div className="relative">
            <button
              className="bg-base-content-darker text-white rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-white"
            >
              <i className="bi bi-person-circle text-xl"></i>
            </button>
            <span
              className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs text-white ${
                showAbsolute ? 'opacity-100' : 'opacity-0'
              } transition-opacity duration-300`}
            >
              
            </span>
          </div>
          <button
            className="ml-4 text-base text-white font-semibold focus:outline-none"
            onClick={handleLogout}
          >
            Cerrar Sesión
          </button>
        </div>
      </div>
    </>
  );
}
