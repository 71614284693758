import React, { useState, useLayoutEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import cx from 'classnames'

export default function Navbar() {
  const navigate = useNavigate();
  const [showElement, setShowElement] = useState(window.innerWidth > 785);

  useLayoutEffect(() => {
    const updateShowElement = () => {
      setShowElement(window.innerWidth > 785);
    };


    window.addEventListener('resize', updateShowElement);


    return () => {
      window.removeEventListener('resize', updateShowElement);
    };
  }, []);
  return (
    <>
      <div className='p-4 sticky top-1 z-30  '>
        <div class="navbar bg-primary rounded-3xl z-30 text-white border">
          <div class="navbar-start">
            <div class="dropdown">
              <div tabindex="0" role="button" class="btn btn-ghost lg:hidden">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
              </div>
              <ul tabindex="0" class="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                <li><a className='text-black' onClick={() => navigate('/')}>Inicio</a></li>
                <li>
                  <li><a className='text-black' onClick={() => navigate('/payments')}>Pagos</a></li>
                </li>
                <li><a className='text-black' onClick={() => navigate('/gallery')}>Galería</a></li>
                <li><a className='text-black' onClick={() => navigate('/contact')}>Contacto</a></li>
                <li><a className='text-black' href='https://agendalosmonachos.000webhostapp.com/'>Iniciar Sesíon</a></li>
              </ul>
            </div>
            <a class={cx("btn btn-ghost text-2xl text-white", { "hidden": !showElement })} onClick={() => navigate('/')}>Jardin Infantil Los Monachos</a>
            <a class={cx("btn btn-ghost text-xl text-white", { "hidden": showElement })} onClick={() => navigate('/')}>Jardin  Los Monachos</a>
          </div>
          <div class="navbar-center hidden lg:flex">
            <ul class="menu menu-horizontal px-1">
              <li><a onClick={() => navigate('/')}>Inicio</a></li>

              <li>
                <details>
                  <summary>Servicios</summary>
                  <ul class="p-2">
                    <li ><a className='text-black' onClick={() => navigate('/payments')}>Pagos</a></li>

                  </ul>
                </details>
              </li>
              <li><a onClick={() => navigate('/gallery')}>Galería</a></li>
              <li><a onClick={() => navigate('/contact')}>Contacto</a></li>
              <li><a href='https://www.facebook.com/share/Eo2fJiJ72xDqNn82/?mibextid=qi2Omg' target="_blank"><i className='bi bi-facebook text-xl '></i></a></li>
              <li><a href='https://www.instagram.com/jardinlosmonachos?utm_source=qr&igsh=MWY0NGVyNXptMTZvNw==' target="_blank"><i className='bi bi-instagram text-xl'></i></a></li>

            </ul>

          </div>
          <div class="navbar-end">
            <a class={cx("btn btn-secondary rounded-full", { "hidden": !showElement })} href="https://agendalosmonachos.000webhostapp.com/">Iniciar Sesíon</a>
          </div>
        </div>
      </div>
    </>
  )
}
