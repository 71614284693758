
import React, { useState, useLayoutEffect } from 'react'
import { Navbar, Button } from 'react-daisyui'
import 'bootstrap-icons/font/bootstrap-icons.css'
import NavbarPage from './NavbarPage';
import Footer from './Footer';
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'

export default function Home() {
  const lettersImageURL = "https://images.unsplash.com/photo-1539632346654-dd4c3cffad8c?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fEVzdHVkaW8lMjBkZSUyMGluZ2wlQzMlQTlzfGVufDB8MHwwfHx8MA%3D%3D";
  const taekwondoImageURL = "https://images.pexels.com/photos/7991209/pexels-photo-7991209.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  const balletImageURL = "https://images.unsplash.com/photo-1596315458574-d99efaea3b3b?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8YmFsbGV0JTIwa2lkc3xlbnwwfDB8MHx8fDI%3D"
  const kidsPlaying1 = "https://images.unsplash.com/photo-1586738730873-bdd59a596a2d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"

  const [showElement, setShowElement] = useState(window.innerWidth > 785);
  const navigate = useNavigate();
  useLayoutEffect(() => {
    const updateShowElement = () => {
      setShowElement(window.innerWidth > 785);
    };

    
    window.addEventListener('resize', updateShowElement);

   
    return () => {
      window.removeEventListener('resize', updateShowElement);
    };
  }, []);

  return (
    <>
      <NavbarPage />
      <div className="h-fit ">

        <div className="hero h-96 bg-base-200" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1560859251-d563a49c5e4a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}  >
          <div className="hero-content text-center">
            <div className='card bg-accent rounded-3xl'>
              <div className='card-body'>
                <h1 className="text-5xl font-bold">Calidad educativa</h1>
                <h2 className="text-4xl font-bold text-primary"> Para cada niño</h2>
                <button className="btn btn-primary rounded-full"  onClick={() => navigate('/gallery')}>Ver más</button>
              </div>
            </div>
          </div>
        </div>

        <div className="divider divider-accent"></div>
        <div className="mt-10">
          <h1 className="text-3xl font-bold text-center">
            Bienvenido al Jardin Infantil
          </h1>
          <h1 className="text-3xl font-bold text-center text-accent ">
            Los Monachos
          </h1>

        </div>
        <div className="mt-10">
          <p className="text-center text-xl">
            Cultivamos la autonomía , fomentando la responsabilidad y el juego independiente.
          </p>
          <p className="text-center text-xl">
            Nuestra visión es forjar niños y niñas autónomos, integrales y felices.
          </p>
        </div>
        <div className={cx("flex items-center justify-center mt-32 mb-32 ", { "hidden": !showElement })}>
          <div className="columns-2">
            <div className="col">
              <div className="flex items-center">
                <i className="bi bi-balloon-heart-fill text-secondary text-4xl"></i>
                <span className="text-secondary text-3xl ml-3">Ambiente seguro</span>
              </div>
              <p className="ml-14 mt-1">Diseñamos nuestras instalaciones</p>
              <p className="ml-14 mt-1">pensando en la seguridad de los niños.</p>


              <div className="flex items-center mt-5">
                <i className="bi bi-dice-5 text-secondary text-4xl"></i>
                <span className="text-secondary text-3xl ml-3">Enfoque lúdico</span>
              </div>
              <p className="ml-14 mt-1">Rompemos con la monotonía y hacemos</p>
              <p className="ml-14 mt-1"> que cada momento de aprendizaje </p>
              <p className="ml-14 mt-1">  sea atractivo. </p>
              <div />
            </div>
            <div className="col">
              <figure><img className="w-96 rounded-xl" src="https://images.inc.com/uploaded_files/image/1920x1080/getty_168318604_69878.jpg" alt="kid" /></figure>
            </div>
          </div>
        </div>
        <div className={cx("flex items-center justify-center mt-32 mb-32 ", { "hidden": !showElement })}>
          <div className="columns-2">
            <div>
              <figure><img className="w-96 rounded-xl" src={kidsPlaying1} alt="kid" /></figure>
            </div>
            <div>
              <div className="flex items-center">
                <i className="bi bi-person-badge-fill text-secondary text-4xl"></i>
                <span className="text-secondary text-3xl ml-3">Personal Capacitado</span>
              </div>
              <p className="ml-14 mt-1">Equipo capacitado, dedicado a </p>
              <p className="ml-14 mt-1">nutrir el desarrollo integral de cada niño.</p>
            </div>
            <div>
              <div className="flex items-center mt-5">
                <i className="bi bi-palette text-secondary text-4xl"></i>
                <span className="text-secondary text-3xl ml-3">Espacios Coloridos</span>
              </div>
              <p className="ml-14 mt-1">Ambientes vibrantes y estimulantes,  </p>
              <p className="ml-14 mt-1"> inspirando imaginación y vitalidad </p>
              <p className="ml-14 mt-1">  en cada rincón. </p>
            </div>


          </div>

        </div>
        <div className={cx("mt-32 mb-32 mx-auto text-center", { "hidden": showElement })}>

          <div>
            <figure><img className="w-96 rounded-xl mx-auto" src="https://images.inc.com/uploaded_files/image/1920x1080/getty_168318604_69878.jpg" alt="kid" /></figure>
          </div>

          <div className='mt-14'>
            <div>
              <i className="bi bi-balloon-heart-fill text-secondary text-4xl"></i>
              <span className="text-secondary text-3xl ml-3">Ambiente seguro</span>
            </div>
            <p className="mt-1">Diseñamos nuestras instalaciones</p>
            <p className="mt-1">pensando en la seguridad de los niños.</p>

            <div className="mt-5">
              <i className="bi bi-dice-5 text-secondary text-4xl"></i>
              <span className="text-secondary text-3xl ml-3">Enfoque lúdico</span>
            </div>
            <p className="mt-1">Rompemos con la monotonía y hacemos</p>
            <p className="mt-1">que cada momento de aprendizaje </p>
            <p className="mt-1">sea atractivo. </p>
          </div>

        </div>
        <div className={cx("mt-32 mb-32 mx-auto text-center ", { "hidden": showElement })}>

          <div>
            <figure><img className="w-96 rounded-xl mx-auto" src={kidsPlaying1} alt="kid" /></figure>
          </div>

          <div className='mt-14'>
            <div >
              <i className="bi bi-person-badge-fill text-secondary text-4xl"></i>
              <span className="text-secondary text-3xl ml-3">Personal Capacitado</span>
            </div>
            <p className="ml-14 mt-1">Equipo capacitado, dedicado a nutrir </p>
            <p className="ml-14 mt-1"> el desarrollo integral de cada niño.</p>


            <div >
              <i className="bi bi-palette text-secondary text-4xl"></i>
              <span className="text-secondary text-3xl ml-3">Espacios Coloridos</span>
            </div>
            <p className="ml-14 mt-1">Ambientes vibrantes y estimulantes,  </p>
            <p className="ml-14 mt-1"> inspirando imaginación y vitalidad </p>
            <p className="ml-14 mt-1">  en cada rincón. </p>
          </div>



        </div>



        <div className={cx('w-full h-fit bg-secondary flex items-center py-28 px-32 text-justify ', { "hidden": !showElement })}>
          <h1 className='text-3xl mr-32 text-justify text-accent'>Visíon</h1>
          <br />
          <p className='text-xl text-white'>El Jardín Infantil Los Monachos ofrece una educación inicial en un espacio que garantiza que el niño y la niña cuenten con ambientes enriquecidos es decir pone al servicio de los niños y niñas adecuadas condiciones de infraestructura, afectos, saberes y dotación didáctica prevaleciendo la garantía de sus derechos.</p>

        </div>
        <div className={cx('w-full bg-secondary py-28 px-4 text-justify', { "hidden": showElement })}>
          <h1 className='text-3xl mb-4 text-center'>Visión</h1>
          <p className='text-xl text-white'>El Jardín Infantil Los Monachos ofrece una educación inicial en un espacio que garantiza que el niño y la niña cuenten con ambientes enriquecidos es decir pone al servicio de los niños y niñas adecuadas condiciones de infraestructura, afectos, saberes y dotación didáctica prevaleciendo la garantía de sus derechos.</p>
        </div>

        <h1 className='text-3xl text-accent justify-center flex mt-10'>Servicios</h1>
        <div className={cx('w-full h-full mt-20 justify-center flex mb-32 ',{"hidden":!showElement})}>

          <div className={cx(' columns-3 flex items-center ', { "hidden": !showElement })}>

            <div className="card card-compact w-56 mx-10 bg-base-100 shadow-xl transition-transform transform hover:scale-105">
              <figure><img src={lettersImageURL} alt="Shoes" /></figure>
              <div className="card-body">
                <h2 className="card-title">Inglés</h2>
                <p>Aprender inglés desde la infancia, un paso hacia el futuro.</p>

              </div>
            </div>
            <div className="card card-compact w-56 mx-10 bg-base-100 shadow-xl transition-transform transform hover:scale-105">
              <figure><img src={taekwondoImageURL} alt="Shoes" /></figure>
              <div className="card-body">
                <h2 className="card-title">Taekwondo</h2>
                <p>Taekwondo en nuestro jardín: fortaleza y diversión para tu hijo.</p>
              </div>
            </div>
            <div className="card card-compact w-56 mx-10 bg-base-100 shadow-xl transition-transform transform hover:scale-105">
              <figure><img src={balletImageURL} alt="Shoes" /></figure>
              <div className="card-body">
                <h2 className="card-title">Ballet</h2>
                <p>Ballet para niños: donde la gracia se encuentra con la alegría.</p>

              </div>
            </div>
          </div>

        </div>
        <div className={cx({ "hidden": showElement })}>
        <div className={cx('justify-center flex')}>
          <div className="carousel w-4/6 ">
            <div id="slide1" className="carousel-item relative w-full items-center justify-center my-12">
              <div className="card card-compact w-56 mx-10 bg-base-100 shadow-xl transition-transform transform hover:scale-105">
                <figure><img src={balletImageURL} alt="Shoes" /></figure>
                <div className="card-body">
                  <h2 className="card-title">Ballet</h2>
                  <p>Ballet para niños: donde la gracia se encuentra con la alegría.</p>

                </div>
              </div>
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a href="#slide4" className="btn btn-circle">❮</a>
                <a href="#slide2" className="btn btn-circle">❯</a>
              </div>
            </div>
            <div id="slide2" className="carousel-item relative w-full items-center justify-center my-12">
              <div className="card card-compact w-56 mx-10 bg-base-100 shadow-xl transition-transform transform hover:scale-105">
                <figure><img src={taekwondoImageURL} alt="Shoes" /></figure>
                <div className="card-body">
                  <h2 className="card-title">Taekwondo</h2>
                  <p>Taekwondo en nuestro jardín: fortaleza y diversión para tu hijo.</p>
                </div>
              </div>
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a href="#slide1" className="btn btn-circle">❮</a>
                <a href="#slide3" className="btn btn-circle">❯</a>
              </div>
            </div>
            <div id="slide3" className="carousel-item relative w-full items-center justify-center my-12">
              <div className="card card-compact w-56 mx-10 bg-base-100 shadow-xl transition-transform transform hover:scale-105">
                <figure><img src={lettersImageURL} alt="Shoes" /></figure>
                <div className="card-body">
                  <h2 className="card-title">Inglés</h2>
                  <p>Aprender inglés desde la infancia, un paso hacia el futuro.</p>

                </div>
              </div>
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a href="#slide2" className="btn btn-circle">❮</a>
                <a href="#slide4" className="btn btn-circle">❯</a>
              </div>
            </div>

          </div>
        </div>
        </div>




        <Footer />

      </div >
    </>
  )
}
