import React from 'react'
import Navbar from './NavbarPage'
import Footer from './Footer'

export default function Contact() {
    const URLMap = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3976.2633907313348!2d-74.09216295019871!3d4.724248517426292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f84c3ae5e9a9d%3A0x39933f3b3d7e828b!2sCl.%20128d%20%2393-41%2C%20Bogot%C3%A1!5e0!3m2!1ses!2sco!4v1706147673460!5m2!1ses!2sco"
    return (
        <>
            <Navbar />

            <section class="" id="contact">
                <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
                    <div class="mb-4">
                        <div class="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
                            <p class="text-base font-semibold uppercase tracking-wide ">
                                Contacto
                            </p>
                            <h2
                                class="font-heading mb-4 font-bold tracking-tight text-primary text-3xl sm:text-5xl">
                                Comunicate con nosotros
                            </h2>

                        </div>
                    </div>
                    
                    <div class="flex items-stretch justify-center">
                        <div class="grid md:grid-cols-2">
                            <div class="h-full pr-6">
                                <p class="mt-3 mb-12 text-lg text-neutral">
                                    ¡Nos encantaría saber de ti! En el Jardín Infantil Los Monachos, valoramos la comunicación y la colaboración. Si tienes alguna pregunta, comentario o inquietud, no dudes en ponerte en contacto con nosotros.
                                </p>
                                <ul class="mb-6 md:mb-0">
                                    <li class="flex">
                                        <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" class="h-6 w-6">
                                                <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                                                <path
                                                    d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z">
                                                </path>
                                            </svg>
                                        </div>
                                        <div class="ml-4 mb-4">
                                            <h3 class="mb-2 text-lg font-medium leading-6 ">Dirección
                                            </h3>
                                            <p class="text-neutral">Cl. 128d #93-41</p>
                                            <p class="text-neutral">Bogota, Colombia</p>
                                        </div>
                                    </li>
                                    <li class="flex">
                                        <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" class="h-6 w-6">
                                                <path
                                                    d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                                </path>
                                                <path d="M15 7a2 2 0 0 1 2 2"></path>
                                                <path d="M15 3a6 6 0 0 1 6 6"></path>
                                            </svg>
                                        </div>
                                        <div class="ml-4 mb-4">
                                            <h3 class="mb-2 text-lg font-medium leading-6 ">Contacto
                                            </h3>
                                            <p class="text-neutral">Telefono: 318 3495172</p>
                                            <p class="text-neutral">E-Mail: jardinmonachos@hotmail.com</p>
                                        </div>
                                    </li>
                                    <li class="flex">
                                        <div class="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" class="h-6 w-6">
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 7v5l3 3"></path>
                                            </svg>
                                        </div>
                                        <div class="ml-4 mb-4">
                                            <h3 class="mb-2 text-lg font-medium leading-6 ">Horarios</h3>
                                            <p class="text-gray-600 text-neutral">Lunes - Viernes: 08:00 - 17:00</p>
                                            <p class="text-gray-600 text-neutral">Sabado &amp; Domingo: 08:00 - 12:00</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="card h-fit max-w-6xl p-5 md:p-12" id="form">
                                <h2 class="mb-4 text-2xl font-bold">¡Cuentanos todo!</h2>
                                <form id="contactForm">
                                    <div class="mb-6">
                                        <div class="mx-0 mb-1 sm:mb-4">
                                            <div class="mx-0 mb-1 sm:mb-4">
                                                <label for="name" class="pb-1 text-xs uppercase tracking-wider"></label><input type="text" id="name" autocomplete="given-name" placeholder="Nombre" class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0" name="name" />
                                            </div>
                                            <div class="mx-0 mb-1 sm:mb-4">
                                                <label for="email" class="pb-1 text-xs uppercase tracking-wider"></label><input type="email" id="email" autocomplete="email" placeholder="Correo" class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0" name="email" />
                                            </div>
                                        </div>
                                        <div class="mx-0 mb-1 sm:mb-4">
                                            <label for="textarea" class="pb-1 text-xs uppercase tracking-wider"></label><textarea id="textarea" name="textarea" cols="30" rows="5" placeholder="Mensaje" class="mb-2 w-full rounded-md border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"></textarea>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" class="w-full bg-primary text-white px-6 py-3 font-xl rounded-md sm:mb-0">Enviar Mensaje</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-wrap justify-center ">
                        <div class="flex-initial shrink xl:w-9/12 lg:w-6/12 z-10">
                            <div class="lg:py- lg:pl-6 mb-6 lg:mb-0">
                                <iframe
                                    src={URLMap}
                                    class="h-32 w-full border-0 rounded-lg shadow-lg" allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {/*
            <div class="flex justify-center items-center h-screen">
                <div class="mb-20 text-gray-800 w-5/6  items-center ">

                    <div class="flex flex-wrap justify-center ">
                        <div class="flex-initial shrink w-full xl:w-5/12 lg:w-6/12 z-10">
                            <div class="lg:py-12 lg:pl-6 mb-6 lg:mb-0">
                                <iframe
                                    src={URLMap}
                                    class="h-80 w-full border-0 rounded-lg shadow-lg" allowfullscreen="" loading="lazy"></iframe>
                            </div>
                        </div>
                        <div class="flex-initial shrink xl:w-7/12 lg:w-6/12 mb-6 md:mb-0 lg:-ml-12">
                            <div class="bg-secondary h-full rounded-lg p-6 lg:pl-12 text-white flex items-center py-12 lg:py-0 z-0" >
                                <div class="lg:pl-12">
                                    <h3 class="text-2xl font-semibold uppercase mb-6 pb-2">Contactanos:</h3>
                                    <h5 class="text-xl font-semibold mb-2">Direccion:</h5>
                                    <p class="mb-6">Cl. 128d #93-41<br /> Bogotá, Colombia</p>
                                    <h5 class="text-xl font-semibold mb-4">Siguenos en redes:</h5>
                                    <div class="flex flex-row">
                                        <i className='bi bi-facebook'></i>
                                        <i className='bi bi-instagram'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            */}
            <Footer />
        </>
    )
}
