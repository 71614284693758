import React from 'react'
import Logo from '../assets/logo.jpeg'
import { useNavigate } from 'react-router-dom'

export default function Footer() {
    const navigate = useNavigate();
    return (
        <>
            <div class="flex items-center justify-center w-14 h-14 rounded-full bg-neutral transition-transform transform hover:scale-105 hover:cursor-pointer fixed bottom-10 right-10">
                <a href="https://wa.me/+573183495172">
                    <i class="bi bi-whatsapp text-xl text-white"></i>
                </a>
            </div>

            <footer class="footer p-10 bg-base-200 text-base-content mt-10">
                <aside>
                    <div>
                        <img class="ml-5 w-24" src={Logo} alt="" />
                    </div>
                    <p>Jardin Infantil Los Monachos<br />Todos los Derechos reservados</p>
                </aside>
                <nav>
                    <header class="footer-title">Links rapidos</header>
                    <a class="link link-hover" onClick={() => navigate('/payments')}>Pagos</a>
                    <a class="link link-hover" onClick={() => navigate('/gallery')}>Galería</a>
                    <a class="link link-hover" onClick={() => navigate('/contact')}>Contacto</a>


                </nav>
                <nav>
                    <header class="footer-title">Contacto</header>
                    <a class="link link-hover" target='_blank' href='https://www.instagram.com/jardinlosmonachos?utm_source=qr&igsh=MWY0NGVyNXptMTZvNw=='><i className='bi bi-instagram mr-3'></i>Instagram</a>
                    <a class="link link-hover" target='_blank' href='https://www.facebook.com/share/Eo2fJiJ72xDqNn82/?mibextid=qi2Omg'><i className='bi bi-facebook mr-3' ></i>Facebook</a>
                    <a class="link link-hover" href=' https://wa.me/+573183495172' target='_blank'><i className='bi bi-whatsapp mr-3'></i>Whatsapp</a>

                </nav>

            </footer>
        </>
    )
}
