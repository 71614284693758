import React from 'react'
import Navbar from './NavbarPage'
import Footer from './Footer'
import ImageKid1 from '../assets/kids(1).jpg'
import ImageKid2 from '../assets/kids(2).jpg'
import ImageKid3 from '../assets/kids(3).jpg'
import ImageKid4 from '../assets/kids(4).jpg'
import ImageKid5 from '../assets/kids(5).jpg'
import ImageKid6 from '../assets/kids(6).jpg'
import ImageKid7 from '../assets/kids(7).jpg'
import ImageKid8 from '../assets/kids(8).jpg'
import ImageKid9 from '../assets/kids(9).jpg'
import ImageKid10 from '../assets/kids(10).jpg'
import ImageKid11 from '../assets/kids(11).jpg'
import ImageKid12 from '../assets/kids(12).jpg'
import ImageKid13 from '../assets/kids(13).jpg'
import ImageKid14 from '../assets/kids(14).jpg'
import ImageKid15 from '../assets/kids(15).jpg'
import ImageKid16 from '../assets/kids(16).jpg'
import ImageKid17 from '../assets/kids(17).jpg'




export default function Gallery() {
  

  return (
    <>
      <Navbar />
      <div className='md:px-32 py-14 sm:px-10'>
        <h1 className='text-primary text-3xl mb-12'>Conocenos</h1>


        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid1} alt="image"/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid2} alt=""/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid3} alt=""/>
            </div>
           
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid4} alt=""/>
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid5} alt=""/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid6} alt=""/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid7} alt=""/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid8} alt=""/>
            </div>
            
          </div>
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid9} alt=""/>
            </div>
            <div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid10} alt=""/>
            </div>
            </div>
            
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid11} alt=""/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid12} alt=""/>
            </div>
            
          </div>
          
          <div class="grid gap-4">
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid13} alt=""/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid14} alt=""/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid15} alt=""/>
            </div>
            <div>
              <img class="h-auto max-w-full rounded-lg transform transition duration-300 hover:scale-110" src={ImageKid16}/>
            </div>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  )
}
