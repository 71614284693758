import React,{useEffect, useState} from 'react'
import Navbar from './NavbarPage'
import Footer from './Footer'
import cx from 'classnames'
import PaymentsVideo from '../assets/InstructivoPagos.mp4'

export default function Payments() {


    const [showElement, setShowElement] = useState(window.innerWidth > 1024);

    useEffect(() => {
        const updateShowElement = () => {
          setShowElement(window.innerWidth > 1024);
        };
    
        // Agrega el listener al tamaño de la ventana
        window.addEventListener('resize', updateShowElement);
    
        // Limpia el listener cuando el componente se desmonta
        return () => {
          window.removeEventListener('resize', updateShowElement);
        };
      }, []);
    return (
        <>
            <Navbar />
            <div class="hero h-96 bg-base-200" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1613794713137-a78aba4be84a?q=80&w=2075&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}>


            </div>
            <div className={cx("mt-20 mx-14 " , { "hidden": !showElement })} >

                <div className='grid grid-cols-2 gap-12'>
                    <div class="col ">
                        <h1 className='text-5xl text-accent mx-32 mb-12'> Pagos</h1>

                        <p className='lg:ml-32 text-lg mb-12'>¡Te damos la bienvenida al sistema de pagos en línea del Jardín Infantil Los Monachos! Aquí podrás efectuar tus pagos de manera sencilla y segura, aprovechando nuestro sistema de Pagos Seguros en Línea (PSE) en colaboración con Crediflores. Realiza tus transacciones de forma cómoda y confiable. </p>
                        <h1 className='text-3xl text-accent mb-12 mx-32'> ¿Como puedo realizar el pago?</h1>
                        <video className="lg:ml-32 h-auto rounded-lg aspect-video w-full" controls>
                            <source src={PaymentsVideo} type="video/mp4" />
                            Tu buscador no soporta este formato.
                        </video>
                    </div>
                    <div class="col mt-20 ">
                        <div className=" transition-transform transform hover:scale-105 ml-52 text-center w-fit" >
                            <a href="https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=8608 " target="_blank">
                                <img class="w-52" src="https://seeklogo.com/images/P/pse-logo-B00717880A-seeklogo.com.png" alt="Logo PSE" />
                                <h2 class="text-center hover:underline text-xl ">Paga aquí</h2>

                            </a>
                        </div>
                    </div>
                </div>               
            </div>
            <div className={cx("mt-20 mx-14 " , { "hidden": showElement })} >

                <div className='grid grid-cols gap-12'>
                    <div class="col ">
                        <h1 className='text-5xl text-accent mx-auto mb-12'> Pagos</h1>

                        <p className='lg:ml-32 text-lg mb-12'>¡Te damos la bienvenida al sistema de pagos en línea del Jardín Infantil Los Monachos! Aquí podrás efectuar tus pagos de manera sencilla y segura, aprovechando nuestro sistema de Pagos Seguros en Línea (PSE) en colaboración con Crediflores. Realiza tus transacciones de forma cómoda y confiable. </p>
                        <h1 className='text-3xl text-accent mb-12 mx-auto'> ¿Como puedo realizar el pago?</h1>
                        <video className="lg:ml-32 h-auto rounded-lg aspect-video w-full" controls>
                            <source src={PaymentsVideo} type="video/mp4" />
                            Tu buscador no soporta este formato.
                        </video>
                    </div>
                    <div class="col mt-20 ">
                        <div className=" transition-transform transform hover:scale-105 m-auto text-center w-fit " >
                            <a href="https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=8608 " target="_blank">
                                <img class="w-52" src="https://seeklogo.com/images/P/pse-logo-B00717880A-seeklogo.com.png" alt="Logo PSE" />
                                <h2 class="text-center hover:underline text-xl ">Paga aquí</h2>

                            </a>
                        </div>
                    </div>
                </div>               
            </div>
            <Footer />
        </>
    )
}

