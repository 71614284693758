import React from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./Home";
import "../style/index.css"
import Payments from "./Payments"
import Contact from "./Contact";
import Gallery from "./Gallery";
import Prueba from "./Prueba"

function App() {
  return (

    <Router>
      <Routes>
        
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Home />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/prueba" element={<Prueba/>}/>
      </Routes>
    </Router>

  )
}


export default App;
